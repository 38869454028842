const signUpConfig = {
    signUpFields: [
      {
        label: 'Email Address',
        key: 'email',
        required: true,
        placeholder: 'your@email.com',
        type: 'email',
        displayOrder: 1,
      },
      {
        label: 'First Name',
        key: 'given_name',
        required: true,
        placeholder: 'Johnny',
        type: 'given_name',
        displayOrder: 2,
      },
      {
        label: 'Last Name',
        key: 'family_name',
        required: true,
        placeholder: 'Appleseed',
        type: 'family_name',
        displayOrder: 3,
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        placeholder: 'Password',
        type: 'password',
        displayOrder: 4,
      },
    ],
  }

  export default signUpConfig