import React from 'react'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table"

import '../App.css'

function Leaderboard(props) {

  let leaderboard = props.data
  let sortedLeaderboard = leaderboard.sort((a, b) => b.locationsFound - a.locationsFound || a.completedEpochTime - b.completedEpochTime)
  let topTenLeaderboard = sortedLeaderboard.slice(0, 10)

  let finalLeaderboard = topTenLeaderboard.map((user) => (
    <tr key={user.userID}>
      <td>{(user.firstName + " " + user.lastName.charAt(0))}</td>
      <td>{user.locationsFound}</td>
    </tr>
  ))

  return (
    <div className="roundedChristmasObject" style={{marginTop: "50px"}}>
      <h4>Leaderboard</h4>
      <Row>
        <Col xs={12}>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Locations Found</th>
              </tr>
            </thead>

            <tbody>
              {leaderboard.length === 0 ? (
                <tr>
                  <td colSpan="6">No Users in the System</td>
                </tr>
              ) : (
                finalLeaderboard
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

    </div>
  )
}

export default Leaderboard
