import React, { useState, useEffect } from 'react'
import { getAllLocations } from '../Services/apiCalls'

import '../App.css'

function CurrentClue(props) {
    const [allLocations, setAllLocations] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        getAllLocationsData()
    }, [])

    function getAllLocationsData() {
        getAllLocations().then(
            (result) => {
                setAllLocations(result)
            },
            (error) => {
                setError(error.message)
            }
        )
    }

    function calculateCurrentClueLocation() {
        const currentUser = props.leaderboard.find(element => element.userID === props.user.attributes.email)
        let startingLocation = currentUser.startingLocation + 1
        let locationsFound = 0

        for (var activeLocation = startingLocation; activeLocation <= 18; activeLocation++) {
            let locationKey = `location${activeLocation}Found`

            if (locationsFound === 18) {
                break
            } else {
                if (currentUser[locationKey] === true) {
                    locationsFound++
                    if (activeLocation === 18) {
                        activeLocation = 0
                    }
                } else {
                    break
                }
            }
        }

        let currentClue = allLocations.find(element => element.locationOrder === activeLocation)
        if (currentClue === null || currentClue === undefined) {
            props.clues(['Error, please refresh the page.', '', '', '',])
        } else {
            props.clues([currentClue.clue1, currentClue.clue2, currentClue.clue3, currentClue.clue4])
        }
    }

    if (error) return <div>Error: {error}</div>

    if (props.buttonActive) {
        return (
            <button className="roundedButtonPressed" onClick={calculateCurrentClueLocation}>Clues</button>
        )
    } else {
        return (
            <button className="roundedButton" onClick={calculateCurrentClueLocation}>Clues</button>
        )
    }
}

export default CurrentClue