import React, { useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import { getLeaderboardUsers, updateLeaderboard } from '../../Services/apiCalls'

// Tower Center at Unity Square
function QR8() {
    const [leaderboard, setLeaderboard] = useState([])
    const [email, setEmail] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [allUserEmails, setAllUserEmails] = useState([])
    const [error, setError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false)

    const currentUser = leaderboard.find(element => element.userID === email)
    let userEmails = []

    useEffect(() => {
        getLeaderboardUsers().then(
            (result) => {
                setLeaderboard(result)
                result.map((user) => (
                    userEmails.push(user.userID)
                ));
                setAllUserEmails(userEmails)
            },
            (error) => {
                setError(error.message)
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    )}, []);

    function isValidEmail(email) {
        if (allUserEmails.includes(email)) {
            return true
        } else {
            return false
        }
    }

    function tallyLocationsFound(email) {
        let numLocationsFound = 0

        for (const key in currentUser) {
            if (currentUser[key] === true) {
                numLocationsFound++
            }
        }

        return numLocationsFound
    }

    function handleEmailChange(email) {
        setEmail(email)
        let isValid = isValidEmail(email)
        if (isValid) {
            setEmailIsValid(true)
        } else {
            setEmailIsValid(false)
        }
    }

    function handleSubmit() {
        updateLeaderboard(email, 'location8Found', true).then(
            () => {
                let locationsFound = tallyLocationsFound(email)
                locationsFound++
                updateLeaderboard(email, 'locationsFound', locationsFound).then(
                    () => {
                        setShowSuccess(true)
                    },
                    (error) => {
                        setError(error.message)
                    }
                )

                if (locationsFound === 18) {
                    const epochTime = Date.now()
                    updateLeaderboard(email, 'completedEpochTime', epochTime).then(
                        () => {
                            console.log(`All 18 clues found at ${epochTime}`)
                        },
                        (error) => {
                            console.log('All 18 clues found, but error logging completed time')
                        }
                    )
                }
            },
            (error) => {
                setError(error.message)
            }
        )
    }

    if (error) return <div>Error: {error}</div>;

    function WelcomeMessage() {
        return (
            <h2 className="header">You Found Tower Center at Unity Square</h2>
        )
    }

    function Instructions() {
        return (
            <h6 style={{textAlign:"center"}}>Enter the email you used to create your account to log that you found this location.</h6>
        )
    }

    function ConfusedMessage() {
        return (
            <h6 style={{textAlign: "center"}}>Not sure what this is for? Go to <a href="https://dashingthroughtheville.com/about">https://dashingthroughtheville.com/about</a> to learn more. Then go <a href="https://dashingthroughtheville.com/play">here</a> to start playing!</h6>
        )
    }

    function SuccessMessage() {
        if (showSuccess) {
            return (
                <div>
                    <h6 style={{color: "green", textAlign: "center"}}>Success! Your find was successfully logged to the leaderboard.</h6>
                    <br />
                    <Nav.Link style={{textAlign: "center"}} href="/play"><button className="roundedButton">Go Back to the Game</button></Nav.Link>
                </div>
            )
        } else {
            return (
                <p></p>
            )
        }
    }

    function SubmitButton() {
        if (emailIsValid) {
            if (currentUser.location8Found === false) {
                return (
                    <button className="roundedButton" type="submit" onClick={handleSubmit}>Log the clue!</button>
                )
            } else {
                return <p></p>
            }
        } else {
            return <p></p>
        }
    }

    function Congratulations() {
        if (emailIsValid) {
            if (currentUser.location8Found) {
                return (
                    <h6 style={{color: "green", textAlign: "center"}}>Congratulations! You've already found this location.</h6>
                )
            } else {
                return (<p></p>)
            }
        } else {
            return (<p></p>)
        }
    }
    
    return(
        <div>
            <Container className="center">
                <WelcomeMessage />
            </Container>

            <Container className="center">
                <h5 style={{textAlign: "center"}}>Sponsored by Taylor Electric</h5>
            </Container>

            <Container className="center">
                <a href="https://www.facebook.com/TaylorElectricBartlesville/" target="_blank" rel="noopener noreferrer">
                    <Image src={require('../../Images/sponsor-logos/taylor-electric.png')} className="floatingRoundedImage" style={{maxHeight: "35vh", padding: "10px", marginBottom: "20px"}} fluid />
                </a>
            </Container>

            <Container className="center">
                <Instructions />
            </Container>

            <Container className="center">
                <input className="roundedInput" name="email" type="email" onChange={(e) => handleEmailChange(e.target.value)} placeholder="your@email.com" required />
                <SubmitButton />
            </Container>

            <Container className="center">
                <Congratulations />
            </Container>

            <Container className="center">
                <SuccessMessage />
            </Container>

            <Container className="center">
                <ConfusedMessage />
            </Container>
            <br />
        </div>
    )
}

export default QR8