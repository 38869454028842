import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import '../App.css'

export default function Home() {
    return (
        <div>
            <div>
                <Image className="floatingImage" src={require('../Images/billboard.png')} fluid />
            </div>

            <Container className="baseline">
                <div className="center">
                    <h3 style={{textAlign: "center"}}>Welcome to</h3>
                </div>
                <div className="centerNoMargin">
                    <h3 style={{color: "#00873E"}}>Dashing Through the 'Ville</h3>
                </div>

                <div className="center">
                    <Nav.Link href="/play"><button className="roundedButton">Join the Hunt!</button></Nav.Link>
                </div>
            </Container>
        </div>
    )
}