import React from 'react'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import '../App.css'

export default function About() {
    return (
        <Container className="baseline">
            <h3 style={{color: "#00873E"}} className="header">Dashing Through the 'Ville</h3>
            <h4 className="header">Benefiting Youth and Family Services of Washington and Nowata Counties.</h4>
            <br />
            <div className="center">
                <Image src={require('../Images/dashing-through-the-ville.png')} className="floatingChristmasImage" style={{maxHeight: "35vh"}} fluid/>
            </div>

            <Container>
            <br />
            <div className="center">
                <p>
                Welcome to <b><i>Dashing Through the 'Ville</i></b>! Brought to you by Leadership Bartlesville 29 to benefit Youth and Family Services. By clicking Play in the top right, or by clicking <a href="https://dashingthroughtheville.com/play">here</a>, you will be able to sign up and participate in our local scavenger hunt from November 28 - December 31. This family-friendly event will provide a way for you to have fun and stay socially distanced!
                </p>
            </div>
            <div className="center">
                <p>
                Once you're signed in, you will be given clues to find a certain location. Once at the correct location there will be a QR code to scan. After you scan the QR code with your phone and confirm that you found the location, you'll be given clues for the next location back on the Play page. There are 18 locations throughout Bartlesville to find and scan. For every QR code scanned, you will be entered into a drawing for a weekly prize, drawn every Sunday. Be sure to follow us on <a href="https://www.facebook.com/dashingthroughtheville/" target="_blank" rel="noopener noreferrer">Facebook</a> for updates, prizes, and information.
                </p>
            </div>
            <div className="center">
                <p>
                During the trip around town you will also learn about various places around town and about Youth and Family Services of Washington County. They serve an average of 1,500 families each year and are the only non-profit in Washington and Nowata county that has a Children’s Resource Center which specializes in serving youth with development delays, disabilities, and disruptive behavior. The resource center provides services to children that are typically in DHS custody. It is up to Youth and Family services to provide for the needs of these children.
                </p>
            </div>
            <div className="center">
                <p>
                This event is free but donations are welcomed and greatly appreciated. All donations go to support Children’s Resource Center at Youth and Family Services to make much needed repairs and buy items for the children at the center. To make a donation, you can go to the Bartlesville Community Foundation website (<a href="http://bartlesvillecf.org/donate" target="_blank" rel="noopener noreferrer">http://bartlesvillecf.org/donate</a>). Please select Leadership Bartlesville as the designation of your donation and put LB29 in the notes section.
                </p>
            </div>
            <div className="center">
                <p>
                We hope you decide to go Dashing Through the 'Ville!
                </p>
            </div>
            </Container>
        </Container>
    )
}