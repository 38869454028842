/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:eec368ae-e440-48b1-9c78-c86834798feb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nTTdnX8kg",
    "aws_user_pools_web_client_id": "7duh113i3jv816sle7q41jk1td",
    "oauth": {}
};


export default awsmobile;
