import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Amplify from 'aws-amplify'
import Leaderboard from '../Components/Leaderboard' 
import CurrentClue from '../Components/CurrentClue'
import { getLeaderboardUsers } from '../Services/apiCalls'
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import signUpConfig from '../Services/signUpConfig'
import awsconfig from '../aws-exports'

import '../App.css'

Amplify.configure(awsconfig)

function Play() {
  const [leaderboard, setLeaderboard] = useState([])
  const [buttonsActive, setButtonsActive] = useState(false)
  const [cluesButtonActive, setCluesButtonActive] = useState(false)
  const [leaderboardButtonActive, setLeaderboardButtonActive] = useState(false)
  const [showingLeaderboard, setShowingLeaderboard] = useState(false)
  const [showingClues, setShowingClues] = useState(false)
  const [clues, setClues] = useState([])
  const [authState, setAuthState] = useState()
  const [authenticatedUser, setAuthenticatedUser] = useState()
  const [error, setError] = useState(null)

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setAuthenticatedUser(authData)
      getAllLeaderboardUsers()
    })
  }, [])

  function getAllLeaderboardUsers() {
    getLeaderboardUsers().then(
        (result) => {
          setLeaderboard(result)
          setButtonsActive(true)
        },
        (error) => {
            setError(error.message)
        }
    )
  }

  let currentUser = {}

  function Progress() {
    if (error) return <div className="center">Error loading your progress.</div>

    if (leaderboard.length !== 0) {
      if (authenticatedUser.signInUserSession === null) {
        currentUser = leaderboard.find(element => element.userID === authenticatedUser.username)
      } else {
        currentUser = leaderboard.find(element => element.userID === authenticatedUser.attributes.email)
      }

      if (currentUser === undefined || currentUser === null) {
        return <p style={{textAlign: "center"}}>Error loading current user</p>
      } else {
        if (currentUser.locationsFound === 18) {
          return (
            <h4 className="center" style={{marginBottom: "30px", textAlign: "center"}}>You found all 18 locations. Great job!</h4>
          )
        } else {
          return (
            <h4 className="center" style={{marginBottom: "30px"}}>You've Found <span style={{color: "#00873E"}}>&nbsp;{currentUser.locationsFound}&nbsp;</span> of 18 Locations</h4>
          )
        }
      }
    } else {
      return (
        <h4 className="center" style={{textAlign: "center"}}>Loading your data</h4>
      )
    }
  }

  function Instructions() {
    if (leaderboard.length !== 0) {
      if (authenticatedUser.signInUserSession === null) {
        currentUser = leaderboard.find(element => element.userID === authenticatedUser.username)
      } else {
        currentUser = leaderboard.find(element => element.userID === authenticatedUser.attributes.email)
      }

      if (currentUser === undefined || currentUser === null) {
        return <p></p>
      } else {
        if (currentUser.locationsFound === 18) {
          return (
            <h6 style={{marginBottom: "30px", textAlign: "center"}}>
              Thank you for playing. Please consider donating to Youth and Family Services. All donations support the Children’s Resource Center to help them make much-needed repairs and to buy items for the children at the center. To make a donation, you can go to the <a href="http://bartlesvillecf.org/donate" target="_blank" rel="noopener noreferrer">Bartlesville Community Foundation website</a>. Please select Leadership Bartlesville as the designation of your donation and put LB29 in the notes section.
            </h6>
          )
        } else {
          return (
            <h6 className="center" style={{marginBottom: "30px", textAlign: "center"}}>
              Use your phone's camera to scan the QR code when you find the location related to these clues.
            </h6>
          )
        }
      }
    } else {
      return <p></p>
    }
  }

  function handleClues(clues) {
    setClues(clues)
    if (showingClues) {
      setCluesButtonActive(false)
      setShowingClues(false)
    } else {
      setCluesButtonActive(true)
      setShowingClues(true)
    }
  }

  function ShowClues() {
    if (leaderboard.length !== 0) {
      if (authenticatedUser.signInUserSession === null) {
        currentUser = leaderboard.find(element => element.userID === authenticatedUser.username)
      } else {
        currentUser = leaderboard.find(element => element.userID === authenticatedUser.attributes.email)
      }
      
      if (currentUser === undefined || currentUser === null) {
        return <p></p>
      } else {
        if (currentUser.locationsFound === 18) {
          if (showingClues) {
            return (
              <div>
                <br /> <br />
                <h5 style={{textAlign: "center"}}>No clues left to find. You've finished Dashing Through the 'Ville!</h5>
              </div>
            )
          } else {
            return <p></p>
          }
        } else {
          let allClues = clues.map((clue) => (
            <li key={clue}>{clue}</li>
          ))
      
          if (showingClues) {
            return (
              <div>
                <br /> <br />
                <Container className="roundedChristmasObject">
                  <h4>Your Clues</h4>
                  <div>
                    <ul>
                      {allClues}
                    </ul>
                  </div>
                </Container>
              </div>
            )
          } else {
            return (
              <p></p>
            )
          }
        }
      }
    } else {
      return (
        <p></p>
      )
    }
  }

  function handleShowLeaderboard() {
    if (leaderboardButtonActive) {
      setLeaderboardButtonActive(false)
    } else {
      setLeaderboardButtonActive(true)
    }

    if (showingLeaderboard) {
      setShowingLeaderboard(false)
    } else {
      setShowingLeaderboard(true)
    }
  }

  function LeaderboardButton() {
    if (leaderboardButtonActive) {
      return (
        <button className="roundedButtonPressed" onClick={handleShowLeaderboard}>Leaderboard</button>
      )
    } else {
      return (
        <button className="roundedButton" onClick={handleShowLeaderboard}>Leaderboard</button>
      )
    }
  }

  function ShowLeaderboard() {
    if (showingLeaderboard) {
      return (
        <Leaderboard data={leaderboard}/>
      )
    } else {
      return (
        <p></p>
      )
    }
  }

  function ButtonRow() {
    if (buttonsActive) {
      return (
        <div className="center">
          <CurrentClue user={authenticatedUser} clues={handleClues} leaderboard={leaderboard} buttonActive={cluesButtonActive} />
          <LeaderboardButton />
        </div>
      )
    } else {
      return <p></p>
    }
  }

  return authState === AuthState.SignedIn && authenticatedUser ? (
    <Container className="baseline">
      <Progress />
      <Instructions />
      <ButtonRow />
      <ShowClues />
      <ShowLeaderboard />

      <br />

      <AmplifySignOut />
    </Container>
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        usernameAlias="email"
        formFields={signUpConfig.signUpFields}
        headerText="Create Your Account"
        slot="sign-up"
      ></AmplifySignUp>

    </AmplifyAuthenticator>
  )
}

export default Play
