import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Home from "./Pages/Home"
import About from "./Pages/About"
import Play from "./Pages/Play"
import QR1 from "./Pages/QRPages/QR1"
import QR2 from "./Pages/QRPages/QR2"
import QR3 from "./Pages/QRPages/QR3"
import QR4 from "./Pages/QRPages/QR4"
import QR5 from "./Pages/QRPages/QR5"
import QR6 from "./Pages/QRPages/QR6"
import QR7 from "./Pages/QRPages/QR7"
import QR8 from "./Pages/QRPages/QR8"
import QR9 from "./Pages/QRPages/QR9"
import QR10 from "./Pages/QRPages/QR10"
import QR11 from "./Pages/QRPages/QR11"
import QR12 from "./Pages/QRPages/QR12"
import QR13 from "./Pages/QRPages/QR13"
import QR14 from "./Pages/QRPages/QR14"
import QR15 from "./Pages/QRPages/QR15"
import QR16 from "./Pages/QRPages/QR16"
import QR17 from "./Pages/QRPages/QR17"
import QR18 from "./Pages/QRPages/QR18"

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

export default function App() {
    return (
    <Router>
        <Navbar collapseOnSelect expand="lg" className="colorNav" variant="dark">
            <Navbar.Brand href="/">Dashing Through the 'Ville</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <Nav.Link style={{color: "white", fontWeight: "bold"}} href="/about">About</Nav.Link>
                    <Nav.Link style={{color: "white", fontWeight: "bold"}} href="/play">Play</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/about">
                <About />
            </Route>
            <Route exact path="/play">
                <Play />
            </Route>
            <Route exact path="/gxowp">
                <QR1 /> {/* Bartlesville Chamber of Commerce */}
            </Route>
            <Route exact path="/phqao">
                <QR2 /> {/* Moxie on Second */}
            </Route>
            <Route exact path="/jdkgi">
                <QR3 /> {/* Kiddie Park */}
            </Route>
            <Route exact path="/zxcvu">
                <QR4 /> {/* ConocoPhillips */}
            </Route>
            <Route exact path="/poihy">
                <QR5 /> {/* Phillips 66 */}
            </Route>
            <Route exact path="/biezt">
                <QR6 /> {/* Truity Credit Union */}
            </Route>
            <Route exact path="/kjwer">
                <QR7 /> {/* Central Fire Station */}
            </Route>
            <Route exact path="/lkjhe">
                <QR8 /> {/* Tower Center at Unity Square */}
            </Route>
            <Route exact path="/zeinw">
                <QR9 /> {/* Sutterfield Downtown */}
            </Route>
            <Route exact path="/vizwq">
                <QR10 /> {/* KWON */}
            </Route>
            <Route exact path="/eibpa">
                <QR11 /> {/* Ascension St. John Jane Phillips */}
            </Route>
            <Route exact path="/ikmns">
                <QR12 /> {/* Elder Care */}
            </Route>
            <Route exact path="/ytrnd">
                <QR13 /> {/* Tri County Tech */}
            </Route>
            <Route exact path="/lznef">
                <QR14 /> {/* Armstrong Bank */}
            </Route>
            <Route exact path="/uycbg">
                <QR15 /> {/* The Koster Team */}
            </Route>
            <Route exact path="/fevth">
                <QR16 /> {/* Tate Boys */}
            </Route>
            <Route exact path="/icpoj">
                <QR17 /> {/* Oklahoma Wesleyan University */}
            </Route>
            <Route exact path="/wdfsk">
                <QR18 /> {/* Danielle Weaver's Office */}
            </Route>
        </Switch>
    </Router>
    )
}