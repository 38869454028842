function getEnv() {
  if (window.location.hostname === 'dashingthroughtheville.com') {
    return 'prod'
  } else {
    return 'dev'
  }
}

export async function getAllLocations() {
  let env = getEnv()
  return fetch(
    `https://8aar5fgfy7.execute-api.us-east-1.amazonaws.com/${env}/get-locations-for-hunt/ScavengerHuntLocations`
  ).then((res) => res.json())
}

export async function getLeaderboardUsers() {
  let env = getEnv()
  return fetch(
    `https://8aar5fgfy7.execute-api.us-east-1.amazonaws.com/${env}/get-leaderboard`
  ).then((res) => res.json())
}

export async function getDataForUser(userID) {
  let env = getEnv()
  const response = await fetch(
    `https://8aar5fgfy7.execute-api.us-east-1.amazonaws.com/${env}/get-leaderboard/${userID}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )

  return response.json()
}

export async function updateLeaderboard(userID, paramsName, paramsValue) {
  let env = getEnv()
  const response = await fetch(
    `https://8aar5fgfy7.execute-api.us-east-1.amazonaws.com/${env}/update-leaderboard/${userID}/${paramsName}/${paramsValue}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )

  return response.json()
}